@font-face { font-family: cubano-regular; 
    src: url('../../public/fonts/Cubano-Font-Family/Cubano/Cubano.ttf'); }

h1,h2,h3{
    font-family: cubano-regular!important;
}

/**
  * Meetup
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes meetup {
    0%, 100% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
    50% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
  @keyframes meetup {
    0%, 100% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
    50% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
  
  .meetup {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    height: 10px;
    width: 10px; }
    .meetup div {
      height: 100%;
      position: absolute;
      width: 100%;
      -webkit-animation: meetup 1s infinite ease;
              animation: meetup 1s infinite ease;
      background: rgb(0, 0, 0);
      border-radius: 100%; }
      .meetup div:nth-child(1) {
        --rotation: 90; }
      .meetup div:nth-child(2) {
        --rotation: 180; }
      .meetup div:nth-child(3) {
        --rotation: 270; }
      .meetup div:nth-child(4) {
        --rotation: 360; }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }